import { useContext } from "react";
import { Context } from "../context";

export default function Row({ letters, isLast, handleToggle }) {
  const {
    wordToGuess,
    handleInput: handleClick,
    usedLetters,
  } = useContext(Context);
  return (
    <div className="keyboard-row">
      {letters.split("").map((l, i) => {
        const letterUsed = usedLetters[l] >= 1;
        return (
          <button
            key={`${l}-${i}`}
            onClick={() => handleClick(l)}
            disabled={letterUsed}
            {...(letterUsed && {
              className: wordToGuess.includes(l) ? "green" : "red",
            })}
          >
            {l}
          </button>
        );
      })}
      {isLast && (
        <button className="keyboard-row__hide-btn" onClick={handleToggle}>
          hide
        </button>
      )}
    </div>
  );
}
