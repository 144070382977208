import { useContext } from "react";

import { Context } from "./context";

export default function WordToGuess() {
  const { wordToGuess } = useContext(Context);
  return (
    <div className="word-to-guess">
      {wordToGuess.split("").map((letter, i) => {
        const key = `${letter}-${i}`;
        return (
          <div key={key} id={key} className="word-to-guess__letter">
            {" "}
          </div>
        );
      })}
    </div>
  );
}
