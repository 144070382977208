import { useEffect } from "react";

import { HANGMANPICS } from "../../utils";
import confetti from "canvas-confetti";

export function useEndGameMessage(
  runningGuess,
  wordToGuess,
  incorrectLetters,
  cb
) {
  useEffect(() => {
    if (runningGuess === wordToGuess) {
      confetti({
        particleCount: 100,
        spread: 70,
        origin: { y: 0.6 },
      });
      return cb("winner");
    }

    if (incorrectLetters.length === HANGMANPICS.length - 1) {
      return cb(`You are a loser: ${wordToGuess}`);
    }
  }, [runningGuess, incorrectLetters.length]);
}

export default useEndGameMessage;
