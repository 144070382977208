import { useContext } from "react";

import { Context } from "./context";

export default function IncorrectLetters() {
  const { incorrectLetters } = useContext(Context);
  return (
    <div className="incorrect-letters__wrapper">
      {incorrectLetters.length > 0 && (
        <>
          <pre>incorrect</pre>
          <ul className="incorrect-letters__list">
            {incorrectLetters.map((char, i) => (
              <li
                key={`incorrect-${char}-${i}`}
                className="incorrect-letters__letter"
              >
                {char}
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
}
