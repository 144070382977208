import { ACTION_TYPES } from "../action-types";
import { replaceStringAt } from "../../utils";

export const runningGuessReducer = (runningGuess, { type, index, letter }) => {
  switch (type) {
    case ACTION_TYPES.CORRECT:
      return replaceStringAt(runningGuess, index, letter);
    default:
      return runningGuess;
  }
};

export const usedLettersReducer = (usedLetters, { type, letter }) => {
  switch (type) {
    case ACTION_TYPES.CORRECT:
    case ACTION_TYPES.INCORRECT:
      return {
        ...usedLetters,
        [letter]: usedLetters[letter] + 1,
      };
    default:
      return usedLetters;
  }
};

export const incorrectLettersReducer = (incorrectLetters, { type, letter }) => {
  switch (type) {
    case ACTION_TYPES.INCORRECT:
      return incorrectLetters.concat(letter);
    default:
      return incorrectLetters;
  }
};

export const endGameReducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPES.END_GAME:
      return true;
    default:
      return state;
  }
};
