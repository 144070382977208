import Stage from "./stage";
import WordToGuess from "./word-to-guess";
import IncorrectLetters from "./incorrect-letters";

import { Keyboard } from "./keyboard";

export default function Hangman() {
  return (
    <>
      <div>
        <h1>
          h<span>_</span>ngman
        </h1>
        <pre>Find the hidden word, be careful, no take backs..</pre>
      </div>
      <div className="hangman__game-wrapper">
        <Stage />
        <div>
          <WordToGuess />
          <IncorrectLetters />
          <Keyboard />
        </div>
      </div>
    </>
  );
}
