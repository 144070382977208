import { useState } from "react";

import Row from "./row";

export default function Keyboard(props) {
  const [keyboardToggle, setToggle] = useState(false);

  function toggleKeyboard() {
    setToggle((t) => !t);
  }

  return (
    <div className="keyboard">
      {keyboardToggle ? (
        <>
          <Row letters="qwertyuiop" />
          <Row letters="asdfghjkl" />
          <Row letters="zxcvbnm" isLast handleToggle={toggleKeyboard} />
        </>
      ) : (
        <button onClick={toggleKeyboard}>show keyboard</button>
      )}
    </div>
  );
}
