import { useReducer, useRef } from "react";

import { alphabetMap, words, combineReducers } from "../../utils";
import {
  runningGuessReducer,
  usedLettersReducer,
  incorrectLettersReducer,
  endGameReducer,
} from "../reducers";

export const combinedReducer = combineReducers({
  runningGuess: runningGuessReducer,
  usedLetters: usedLettersReducer,
  incorrectLetters: incorrectLettersReducer,
  endGame: endGameReducer,
});

const hangmanReducer = (state, action) => ({
  wordToGuess: state.wordToGuess,
  ...combinedReducer(state, action),
});

export function useHangmanReducer(reducer = hangmanReducer) {
  const word = useRef(words.random());

  const [state, dispatch] = useReducer(reducer, {
    runningGuess: "_".repeat(word.current.length),
    usedLetters: alphabetMap,
    wordToGuess: word.current,
    incorrectLetters: [],
  });

  return [state, dispatch];
}

export default useHangmanReducer;
