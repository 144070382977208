/**
 * changes the value of a specific index within a string.
 *
 * @param {String} string string to *mutate*
 * @param {Int} index target index
 * @param {String} replacement new value
 * @returns {String}
 */
export function replaceStringAt(string, index, replacement) {
  if (index >= string.length) {
    return string.valueOf();
  }

  const array = string.split("");
  array[index] = replacement;
  return array.join("");
}
