export function findAllDomElements(letter, cb) {
  const elements = getAllElementsByPartialId(letter);

  if (elements.length) {
    elements.forEach((element) => {
      const [char, index] = element.id.split("-");
      cb([char, parseInt(index, 10)]);

      element.classList.add("appear");
      setTimeout(() => (element.innerHTML = char));
    });
  }
}

function getAllElementsByPartialId(letter) {
  return document.querySelectorAll(`[id^='${letter}-']`);
}
