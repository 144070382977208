import { useContext } from "react";

import { Context } from "./context";

import { HANGMANPICS } from "../utils/hangman";
export default function Stage() {
  const { incorrectLetters } = useContext(Context);
  return (
    <div className="stage__wrapper">
      <pre className="stage">{`${HANGMANPICS[incorrectLetters.length]}`}</pre>
    </div>
  );
}
