export const HANGMANPICS = [
  `
  +---+
  |   |
      |
      |
      |
      |
=========`,
  `
  +---+
  |   |
  O   |
      |
      |
      |
=========`,
  `
  +---+
  |   |
  O   |
  |   |
      |
      |
=========`,
  `
  +---+
  |   |
  O   |
 /|   |
      |
      |
=========`,
  `
  +---+
  |   |
  O   |
 /|\\  |
      |
      |
=========`,
  `
  +---+
  |   |
  O   |
 /|\\  |
 /    |
      |
=========`,
  `
  +---+
  |   |
  O   |
 /|\\  |
 / \\  |
      |
=========`,
];

Array.prototype.random = function () {
  return this[Math.floor(Math.random() * this.length)];
};

// TODO: word bank
export const words = [
  "ant",
  "baboon",
  "badger",
  "bat",
  "bear",
  "beaver",
  "camel",
  "cat",
  "clam",
  "cobra",
  "cougar",
  "coyote",
  "crow",
  "deer",
  "dog",
  "donkey",
  "duck",
  "eagle",
  "ferret",
  "fox",
  "frog",
  "goat",
  "goose",
  "hawk",
  "lion",
  "lizard",
  "llama",
  "mole",
  "monkey",
  "moose",
  "mouse",
  "mule",
  "newt",
  "otter",
  "owl",
  "panda",
  "parrot",
  "pigeon",
  "python",
  "rabbit",
  "ram",
  "rat",
  "raven",
  "rhino",
  "salmo",
  "seal",
  "shark",
  "sheep",
  "skunk",
  "sloth",
  "snake",
  "spider",
  "stork",
  "swan",
  "tiger",
  "toad",
  "trout",
  "turkey",
  "turtle",
  "weasel",
  "whale",
  "wolf",
  "wombat",
  "zebra",
];
