const toChars = (_, index) => String.fromCharCode(index + 65);
const alphabet = Array.from(Array(26)).map(toChars);

// creates an object with each letter as a key, with a value of 0
// to count each letter used in the game since it does not allow
// duplicate presses
export const alphabetMap = alphabet.reduce((obj, letter) => {
  obj[letter.toLowerCase()] = 0;
  return obj;
}, {});

export default alphabetMap;
