import { useEffect } from "react";
import { ALPHA_NUMERIC_REGEX, IGNORE_KEYS } from "../../utils/constants";

export function useEventListener(type, cb) {
  useEffect(() => {
    const onKeyPress = (e) => {
      const key = e.key.toLowerCase();
      if (!IGNORE_KEYS.includes(key) && ALPHA_NUMERIC_REGEX.test(key)) {
        cb(key);
      }
    };

    window.addEventListener(type, onKeyPress);
    return () => {
      window.removeEventListener(type, onKeyPress);
    };
  }, [type, cb]);
}

export default useEventListener;
