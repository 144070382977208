import Hangman from "./hangman";

import { Context } from "./context";
import { ACTION_TYPES } from "./action-types";
import { findAllDomElements } from "../utils";

import {
  useHangmanReducer,
  useEventListener,
  useEndGameMessage,
} from "./hooks";

export function App() {
  const [state, dispatch] = useHangmanReducer();
  const { wordToGuess, runningGuess, usedLetters, incorrectLetters, endGame } =
    state;

  function handleInput(letter) {
    if (usedLetters[letter] > 0 || endGame) return;

    if (wordToGuess.includes(letter)) {
      findAllDomElements(letter, ([letter, index]) => {
        dispatch({ type: ACTION_TYPES.CORRECT, letter, index });
      });
      return;
    } else {
      dispatch({ type: ACTION_TYPES.INCORRECT, letter });
    }
  }

  useEventListener("keyup", handleInput);
  useEndGameMessage(runningGuess, wordToGuess, incorrectLetters, (message) => {
    dispatch({ type: ACTION_TYPES.END_GAME });
    setTimeout(() => alert(message), 1000);
  });

  return (
    <Context.Provider value={{ ...state, handleInput }}>
      <Hangman />
    </Context.Provider>
  );
}
